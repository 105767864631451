import React, { useState, useEffect } from 'react';
import DocumentationBox from './DocumentationBox';
import ApiService from './../api/api.service';
import { Doc } from './../types/types';


interface MainSectionProps {
  onDocSelect: (doc: Doc) => void; // Function type
}

const MainSection: React.FC<MainSectionProps> = ({ onDocSelect }) => {
  const [docs, setDocs] = useState<Doc[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiService.getDocList();
        setDocs(response);  // Cast to Doc[]        
      } catch (error) {
        console.error('Error fetching documentation data:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="nc-PageHomeDemo2 relative">
      <div className="container relative">
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8 mt-8 lg:mt-10">
          {docs.map((doc: Doc) => (
            <DocumentationBox 
              key={doc.id}
              id={doc.id}
              slug={doc.slug || 'No alias available'}
              title={doc.title}
              description={doc.description || 'No description available'}
              category={doc.category || 'No categories available'}
              onClick={() => onDocSelect(doc)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MainSection;
