import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import bcrypt from 'bcryptjs';
import Header from './components/Header';
import MainSection from './components/MainSection';
import Footer from './components/Footer';
import DocumentationDetail from './components/DocumentationDetail';
import wikiData from './data/wiki_data.json'; // Adjust the import as per your JSON data location

import AuthService from "./api/auth.service";
import ApiService from './api/api.service';

import { Doc } from './types/types';


type State = {
  redirect: string | null,
  username: string,
  password: string,
  loading: boolean,
  message: string
};





// interface Doc {
//   index: number;
//   alias: string;
//   id: string;
//   title: string;
//   desc: string;
//   categories: string;
//   href: string;
//   // Add any other fields that are present in your data
// }



interface LoginResponse {
  status: number;
  data: {
    jwt?: string;
  };
}

interface UserData {
  identifier: string;
  password: string;
}



// Dummy authentication data
// const dummyUsername = 'admin';
// const dummyHashedPassword = "$2a$10$xW3hjn1WPh/eP2zoA55Ov.IGcnd2JiHhHJiv510Z/s4NoFxAZF102" //bcrypt.hashSync('giessenpark', 10);



const App = () => {


  // States
  const [docs, setDocs] = useState<Doc[]>([]);
  const [filteredDocs, setFilteredDocs] = useState<Doc[]>([]);
  // const [isSignedIn, setIsSignedIn] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(() => {
    const storedState = localStorage.getItem('isSignedIn');
    return storedState ? JSON.parse(storedState) : false;
  });

  console.log("isSignedIn", isSignedIn)

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  // const myToken = localStorage.getItem('token');
  // console.log("myToken", myToken)

  const checkLogin = async (userData: UserData): Promise<boolean> => {
    try {
      const response: LoginResponse = await AuthService.login(userData);
  
      if (response.status === 200 && response.data.jwt) {
        localStorage.setItem("token", response.data.jwt);
        //console.log("response", response.data.jwt);
        handleSignInOut(true);
        return true;
      } else {
        console.log("No Data Token Available");
        handleSignInOut(false);
        return false;
      }
    } catch (error) {
      console.log("Error", error);
      handleSignInOut(false);
      return false;
    }
  };

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
  
    const formData = new FormData(event.currentTarget);
    const username: string = formData.get('username') as string;
    const password: string = formData.get('password') as string;
  
    // console.log("username", username);
    // console.log("password", password);
  
    const userData: UserData = {
      identifier: username,
      password: password
    };
    await checkLogin(userData);
  };

  // useEffect(() => {
  //   setDocs(wikiData);
  //   setFilteredDocs(wikiData);
  // }, []);


  useEffect(() => {
    fetchDocs();
  }, []);

  const fetchDocs = async () => {
    try {
      // Replace 'getDocs' with the actual function name you have defined in your ApiService
      //const response = await ApiService.getDocs();


      const response = {
        "aweqwe" : "asfaf"
      }


      // setDocs(response.data);
      // setFilteredDocs(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };



  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = event.target.value.toLowerCase();
    const filtered = docs.filter(doc => doc.title.toLowerCase().includes(searchText));
    setFilteredDocs(filtered);
  };

  const handleDocSelect = (doc: Doc) => {
    console.log("Selected documentation ID:", doc.id);
    // Add your logic here, e.g., navigate to the detailed documentation page
  };

  const handleSignInOut = (isAuthenticated: boolean) => {

    console.log("handleSignInOut called", isAuthenticated)
    //localStorage.removeItem("user");

    setIsSignedIn(isAuthenticated);
    localStorage.setItem('isSignedIn', JSON.stringify(isAuthenticated));
  };



  const LoginForm = () => {
    return (
      <div className="container relative pt-6 sm:pt-10 pb-16 lg:pt-20 lg:pb-28">
                <div className="p-5 mx-auto bg-white rounded-xl sm:rounded-3xl lg:rounded-[40px] shadow-lg sm:p-10 lg:p-16 dark:bg-neutral-900">
                  <header className="text-center max-w-2xl mx-auto - mb-14 sm:mb-16 lg:mb-20 ">
                    <h2 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">Login</h2>
                    <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">Welcome to Wiki Giessenpark. Please login in to view the documentations.</span>
                  </header>
                  <div className="max-w-md mx-auto space-y-6">
                    <form className="grid grid-cols-1 gap-6" onSubmit={handleLogin}>
                    <label className="block">
                        <span className="text-neutral-800 dark:text-neutral-200">
                          User Name
                        </span>
                        <input
                          type="text"
                          name="username"
                          className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200/50 bg-white dark:border-neutral-500 dark:focus:ring-primary-500/30 dark:bg-neutral-900 rounded-full text-sm font-normal h-11 px-4 py-3 mt-1"
                          placeholder="Username"
                          onChange={e => setUsername(e.target.value)}
                        />
                      </label>
                      <label className="block">
                        <span className="text-neutral-800 dark:text-neutral-200">
                          Password
                        </span>
                        <input
                          type="Password"
                          name="password"
                          className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200/50 bg-white dark:border-neutral-500 dark:focus:ring-primary-500/30 dark:bg-neutral-900 rounded-full text-sm font-normal h-11 px-4 py-3 mt-1"
                          onChange={e => setPassword(e.target.value)}
                        />
                      </label>
                      <button className="nc-Button flex-shrink-0 relative h-auto inline-flex items-center justify-center rounded-full transition-colors border-transparent bg-primary-700 hover:bg-primary-6000 text-primary-50 text-sm sm:text-base font-medium py-3 px-4 sm:py-3.5 sm:px-6"
                        type="submit">
                        Login
                      </button>
                    </form>
                  </div>
                </div>
              </div>
    );
  };



  return (
    <Router>
      <div className="app-container bg-[#f8f8f8] text-base dark:bg-neutral-900/95 text-neutral-900 dark:text-neutral-200 font-body">
        <div className="nc-Header sticky top-0 w-full z-40">
          <Header
            onSearchChange={handleSearchChange}
            onSignInOut={handleSignInOut}
            isSignedIn={isSignedIn} // Pass the isSignedIn state here
          />
        </div>

        <main className="mb-6">
          <Routes>
            <Route path="/documentation/:slug/:id" element={isSignedIn ? <DocumentationDetail /> : <LoginForm />} />


            <Route path="/" element={isSignedIn ? <MainSection onDocSelect={handleDocSelect} /> : <LoginForm />} />
        



          </Routes>
        </main>

        <Footer />
      </div>
    </Router>





  );
};

export default App;

