import axios, { AxiosResponse } from 'axios';

import { Doc, DocDetails } from './../types/types';

const API_BASE_URL = 'http://strapidatagp-env-1.eba-e2esnnmd.eu-central-1.elasticbeanstalk.com'; // Replace with your API base URL

interface IncommingDoc {
  id: number;
  Title: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  description: string;
  Category: string;
  slug: string;
}



const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  // headers: {
  //   'Content-Type': 'application/json',
  // },
});

// Function to retrieve JWT token from localStorage
//const getAuthToken = () => localStorage.getItem('token');


const getAuthToken = () => {
  const authToken = localStorage.getItem('token');
  if (authToken) {
    return authToken
  } 
}

// Axios request interceptor to attach the JWT token to every request
axiosInstance.interceptors.request.use((config) => {
  const token = getAuthToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    window.location.href = '/';
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

class ApiService {

    // // Get all documents
    static async getDocList(): Promise<Doc[]> {
      try {
        const response = await axiosInstance.get<{ data: { id: number; attributes: IncommingDoc }[] }>('/api/wiki-documentations');
        return response.data.data.map(item => ({
          id: item.id,
          slug: item.attributes.slug,
          title: item.attributes.Title, // Assuming 'title' is the correct name in your Doc type
          description: item.attributes.description,
          category: item.attributes.Category,
          Title: item.attributes.Title,
          createdAt: item.attributes.createdAt,
          updatedAt: item.attributes.updatedAt,
          publishedAt: item.attributes.publishedAt,
          Category: item.attributes.Category
        }));
      } catch (error) {
        console.error('Error fetching documents:', error);
        throw error; // rethrow the error for further handling
      }
    }


    static async getDocById(docId: string): Promise<DocDetails> {
      try {
        // Adjust the type definition to include the nested 'data' structure
        const response = await axiosInstance.get<{ data: { id: number; attributes: any } }>(`/api/wiki-documentations/${docId}?populate=deep`);
        // console.log("getDocById", response.data.data);
        const { id, attributes } = response.data.data;
        if (attributes) {
          return {
            id, // Using destructured 'id'
            title: attributes.Title,
            description: attributes.description,
            category: attributes.Category,
            slug: attributes.slug,
            cover: attributes.cover?.data?.attributes, // Assuming you need the cover image attributes
            blocks: attributes.blocks, // Assuming you want to keep the blocks as they are
          };
        } else {
          throw new Error('Document not found');
        }
      } catch (error) {
        console.error('Error fetching documents:', error);
        throw error;
      }
    }
    
    
    
    



  // // Get all documents
  // static async getDocs(): Promise<AxiosResponse<Doc[]>> {
  //   return axiosInstance.get<Doc[]>('/api/articles');
  // }

  // // Get a single document by ID
  // static async getDocById(id: number): Promise<AxiosResponse<Doc>> {
  //   return axiosInstance.get<Doc>(`/docs/${id}`);
  // }

  // // Add a new document
  // static async addDoc(doc: Doc): Promise<AxiosResponse<Doc>> {
  //   return axiosInstance.post<Doc>('/docs', doc);
  // }

  // // Update a document
  // static async updateDoc(id: number, doc: Partial<Doc>): Promise<AxiosResponse<Doc>> {
  //   return axiosInstance.put<Doc>(`/docs/${id}`, doc);
  // }

  // // Delete a document
  // static async deleteDoc(id: number): Promise<AxiosResponse<void>> {
  //   return axiosInstance.delete<void>(`/docs/${id}`);
  // }
}

export default ApiService;
