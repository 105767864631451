import React from 'react';

const Footer: React.FC = () => {
  return (
    <div className="footer gp-footer nc-Footer relative py-16 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container">
        <h6 className="copyright-text text-center">
        <a href="https://www.giessenpark.com/" target="_blank" rel="noopener noreferrer">Giessenpark GmbH</a> © {new Date().getFullYear()}. Alle Rechte vorbehalten
          <span> |</span> 
          <a href="http://www.webzenz.ch/" target="_blank" rel="noopener noreferrer"> Webagentur</a>
        </h6>
      </div>
    </div>
  );
};

export default Footer;
