import axios from "axios";

const API_URL = "http://strapidatagp-env-1.eba-e2esnnmd.eu-central-1.elasticbeanstalk.com"

// Create instance called instance
const instance = axios.create({
    baseURL: API_URL,
    // headers: {'Authorization': "Bearer "+getAuthToken()}
});


const AuthService = {

    login: (userdata: any) =>
    instance({
        'method':'POST',
        'url':'/api/auth/local',
        'data': userdata
    }),
};

export default AuthService;


// class AuthService {









    
//   login(username: string, password: string) {


//     console.log("username login", username)
//     console.log("password login", password)


//     return axios
//       .post(API_URL + "local", {
//         "identifier" : username,
//         "password" : password
//       })
//       .then(response => {
//         if (response.data.accessToken) {
//           localStorage.setItem("user", JSON.stringify(response.data));
//         }

//         return response.data;
//       })
//       .catch(function (error) {
//         console.log(error);
//       });
//   }

//   logout() {
//     localStorage.removeItem("user");
//   }

//   register(username: string, email: string, password: string) {
//     return axios.post(API_URL + "signup", {
//       username,
//       email,
//       password
//     });
//   }

//   getCurrentUser() {
//     const userStr = localStorage.getItem("user");
//     if (userStr) return JSON.parse(userStr);

//     return null;
//   }
// }

// export default new AuthService();