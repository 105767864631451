import React from 'react';
import titleBgImg from "./../images/title_bg.jpg";
import { Link } from 'react-router-dom';

interface DocumentationBoxProps {
  id: number;
  title: string;
  slug: string;
  description: string;
  category: string;
  onClick: () => void; // Assuming onClick doesn't take any arguments
}

const DocumentationBox: React.FC<DocumentationBoxProps> = ({ id, slug, title, description, category, onClick }) => (

  <div className="documentation-box nc-Card11 relative flex flex-col group rounded-3xl overflow-hidden bg-white dark:bg-neutral-900 h-full" >

    <Link
      to={`/documentation/${slug}/${id}`}
    >
      <div className="block flex-shrink-0 relative w-full rounded-t-3xl overflow-hidden z-10" >
        <div>
          <div className="nc-PostFeaturedMedia relative">
            <img src={titleBgImg} sizes="480px, 108px" alt="featured" />
          </div>
        </div>
      </div>

      <span className="category absolute top-3 inset-x-3 z-10">
        {category}
      </span>

      <div className="p-4 flex flex-col space-y-3">

        <h3 className="nc-card-title block text-base font-semibold text-neutral-900 dark:text-neutral-100">
          <span className="line-clamp-2" title={title}>
            {title}
          </span>
        </h3>

        <p>{description.split(' ').slice(0, 10).join(' ')}...</p>
      </div>

    </Link>

  </div>

);

export default DocumentationBox;