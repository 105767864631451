import React, { useState } from 'react';
import bcrypt from 'bcryptjs';
import logoWikiImg from "./../images/wiki_logo.png";
import { Link } from 'react-router-dom';

interface HeaderProps {
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSignInOut: (isAuthenticated: boolean) => void;
  isSignedIn: boolean;
}





const Header: React.FC<HeaderProps> = ({ onSearchChange, onSignInOut, isSignedIn }) => {

  const handleSignOut = () => {
    onSignInOut(false); // Sign out
    localStorage.removeItem('isSignedIn');
  };

  const renderSearchForm = () => {
    return (
      <div className="relative group">
        <div className="absolute inset-0"></div>

        <input
          type="text"
          onChange={onSearchChange}
          className="pr-5 md:pr-10 !w-40 md:!w-full group-hover:border-slate-300 dark:group-hover:border-slate-400 dark:placeholder:text-neutral-400"
          placeholder="Search documentation..."
        />
        <span className="absolute top-1/2 -translate-y-1/2 right-3 text-neutral-500 dark:text-neutral-400">
          <svg
            className="h-5 w-5"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22 22L20 20"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        <div className="absolute inset-0"></div>
      </div>
    );
  };

  return (
    <div className="nc-MainNav2 relative z-10 bg-white dark:bg-slate-900 ">
      <div className="container">
        <div className="h-20 flex justify-between">
          <div className="flex lg:flex-1 items-center space-x-3 sm:space-x-8">
            <Link
              to="/"

            >
              <img
                className="wiki-logo"
                src={logoWikiImg}
              />
            </ Link>
            <div className="h-10 border-l border-slate-200 dark:border-slate-700"></div>

            <div className="flex-grow max-w-xs">
              <input
                type="text"
                onChange={onSearchChange}
                className="pr-5 md:pr-10 !w-40 md:!w-full group-hover:border-slate-300 dark:group-hover:border-slate-400 dark:placeholder:text-neutral-400"
                placeholder="Search documentation..."
              />

            </div>

            {!isSignedIn ?
              <>

              </>
              :
              <button
                className='nc-Button flex-shrink-0 relative h-auto inline-flex items-center justify-center rounded-full transition-colors border-transparent bg-primary-700 hover:bg-primary-6000 text-primary-50 text-sm sm:text-base font-medium py-3 px-4 sm:py-3.5 sm:px-6  '
                onClick={handleSignOut}>
                Sign Out</button>
            }

          </div>
        </div>
      </div>
    </div>
  );
};



export default Header;