import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Doc, DocDetails, Block } from '../types/types';
import data from '../data/wiki_data.json';
import { TailSpin } from 'react-loader-spinner';
import ApiService from './../api/api.service';
import { marked } from 'marked';
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";





const DocumentationDetail = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { id, slug } = useParams<{ id: string; slug: string }>();
  const [doc, setDoc] = useState<DocDetails | null>(null);

  useEffect(() => {
    if (id) {
      const fetchDoc = async () => {
        try {
          const fetchedDoc = await ApiService.getDocById(id);
          setDoc(fetchedDoc);
        } catch (error) {
          console.error('Error fetching document:', error);
        }
      };

      fetchDoc();
    }
  }, [id]);


  const handleBack = () => {
    navigate(-1);
  };

  const renderBlock = (block: Block) => {
    switch (block.__component) {
      case 'shared.rich-text':

      return (
        <div className="mt-2 mb-2" key={block.id}>
          <Markdown children={block.body} remarkPlugins={[remarkGfm]} />
        </div>
      );

      case 'shared.media':
        return block.file.data.map((file) => {
          if (file.attributes.mime.startsWith('image/')) {
            return ( 
              <div className="mt-2 mb-2" key={file.id}>
                <img key={file.id} src={file.attributes.url} alt={file.attributes.alternativeText || 'Image'} />
              </div>
            );
          }
          if (file.attributes.mime === 'application/pdf') {
            return (
              <div className="mt-5 mb-5" key={file.id}>
                <iframe 
                  key={file.id}
                  src={file.attributes.url}
                  style={{ width: '100%', height: '500px' }} // Adjust the size as needed
                  title={file.attributes.name}
                ></iframe>
              </div>
            );
          }
          return null;
        });
      default:
        return null;
    }
  };


  const coverImageUrl = doc?.cover?.url;
  const coverImageAlternativeText = doc?.cover?.alternativeText;
  const coverImageHeight = doc?.cover?.height;
  const coverImageWidth = doc?.cover?.width;
 
  if (!doc) return (
    <div className="nc-PageSingle pt-8 lg:pt-16">
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto">
          <div className="nc-SingleHeader ">
            <div className="space-y-5">
              <div>
                <TailSpin
                  height="100"
                  width="100"
                  color="blue"
                  ariaLabel="loading"
                />
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16">

      {/* Cover Image */}
      {coverImageUrl && (
        <div className="mb-5">
          <img
            src={coverImageUrl}
            alt={coverImageAlternativeText || 'Header Image'}
            className="w-full object-cover object-center"
            style={{ maxHeight: '500px' }}
          />
        </div>
      )}

      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto">
          <div className="nc-SingleHeader ">
            <div className="space-y-5">
              <div className="nc-CategoryBadgeList flex flex-wrap space-x-2" data-nc-id="CategoryBadgeList">
                <button
                  className="transition-colors hover:text-white duration-300 nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium text-xs !px-3 text-red-800 bg-blue-100 hover:bg-red-800"
                  onClick={handleBack}>
                  Back
                </button>
              </div>

              {/* Title */}
              <h1 className="text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-5xl dark:text-neutral-100 max-w-4xl" title={doc.title}>
                {doc.title}
              </h1>

              {/* Title */}
              <h3 className="text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-5xl dark:text-neutral-100 max-w-4xl" title={doc.title}>
                {doc.category}
              </h3>


              {/* Description */}
              <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
                <p>
                  {doc.description}
                </p>
              </span>

            </div>
          </div>
        </div>

      </header>



      <main className="container mx-auto">
        <div className="max-w-screen-md mx-auto">
          <div className="nc-SingleContent">
            <div className="space-y-5">
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
            </div>

            <div>
            {doc.blocks&& doc.blocks.map(block => renderBlock(block)) }
              {/* ... other component content ... */}
            </div>


          </div>
        </div>
      </main>



    </div>
    // {doc.image && <img src={doc.image} alt={doc.title} />}

  );
};

export default DocumentationDetail;
